/**
* Gravity Department
* http://manuals.gravitydept.com
*
* Copyright 2014 Gravity Department. All rights reserved.
*/


// ==============================================
// Hamburger Menus
// ==============================================

var burger = $('.burger');
var body = $('body');

burger.on('click', function (e) {
    e.preventDefault();

    body.toggleClass('nav--active')
        .toggleClass('overlay--active');
});
