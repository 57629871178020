/**
* Gravity Department
* http://manuals.gravitydept.com
*
* Copyright 2014 Gravity Department. All rights reserved.
*/


// ==============================================
// Toggle
// ==============================================

$('[data-toggle]').on('click', function (e) {
    e.preventDefault();

    var toggleTarget = $(this).attr('data-toggle');
    var elem = $('#' + toggleTarget);

    if (elem.attr('data-toggle-target') === 'hide') {
        elem.attr('data-toggle-target', 'show');
    } else {
        elem.attr('data-toggle-target', 'hide');
    }
});


// ==============================================
// Overlay
// ==============================================

$('.overlay').on('click', function (e) {
    body.toggleClass('nav--active')
        .toggleClass('overlay--active');
});
